<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_modal_lista_richieste">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content" v-if="id_sessione_selezionata">
        <div class="modal-header">
          <h5 class="modal-title">
            Visualizza richieste per la sessione
            {{ sessione_selezionata.nome }} del {{ sessione_selezionata.data }}
          </h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="kt_modal_modal_lista_richieste_close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="card-body p-6">
            <Datatable
              :table-header="tableHeader"
              :table-data="richieste_biglietti.richieste_biglietti"
              :total="richieste_biglietti.richieste_biglietti.length"
              :current-page="currentPage"
              :rows-per-page="fetchRows"
              :sortLabel="sortColumn"
              :order="sortOrder"
              @sort="setSortOrderColumn"
              @items-per-page-change="setFetchRowsEventi"
              @current-change="setCurrentPageEventi"
              :checkedAll="checkAll"
              @checkboxClicked="
                checkAll = !checkAll;
                lista_richieste_biglietti.forEach((element) => {
                  element.selected = checkAll;
                });
              "
            >
              <template v-slot:cell-nome_circolo="{ row: data }">{{
                data.nome_circolo
              }}</template>
              <template v-slot:cell-data_ora_richiesta="{ row: data }">{{
                data.data_ora_richiesta
              }}</template>
              <template v-slot:cell-numero_biglietti="{ row: data }">
                <div class="d-flex flex-row">
                  <div v-if="data.isEditNumeroBiglietti">
                    <input
                      :name="`sessioni[${idx}].nome`"
                      type="number"
                      :value="data.numero_biglietti"
                      class="form-control"
                      @input="data.numero_biglietti = $event.target.value"
                    />
                  </div>
                  <p class="mb-0" v-else>{{ data.numero_biglietti }}</p>
                  <button
                    v-if="!data.confermata"
                    class="btn btn-sm dropdown p-1"
                    type="button"
                    @click="
                      data.isEditNumeroBiglietti = !data.isEditNumeroBiglietti
                        ? true
                        : false
                    "
                  >
                    <i class="bi bi-pen text-dark fs-4"></i>
                  </button></div
              ></template>
              <template v-slot:cell-confermata="{ row: data }">
                <span class="text-success" v-if="data.confermata">SI</span>
                <span class="text-danger" v-else>NO</span></template
              >
              <template v-slot:cell-options="{ row: data }">
                <div class="btn-group" v-if="!data.confermata">
                  <button
                    class="btn btn-sm dropdown p-0 ps-2"
                    type="button"
                    @click="confirm(data)"
                  >
                    <i class="bi bi-check-lg text-success fs-4"></i>
                  </button>
                  <button
                    class="btn btn-sm dropdown p-1"
                    type="button"
                    @click="refuse(data)"
                  >
                    <i class="bi bi-x-lg text-danger fs-4"></i>
                  </button>
                </div>
              </template>
            </Datatable>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="kt_modal_modal_lista_richieste_close_button"
          >
            Chiudi
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";

import { defineComponent, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";
import { format } from "@/composables/formatDate";

import "vue3-date-time-picker/dist/main.css";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import richieste_biglietti from "@/JSON/richieste_biglietti.json";
import { callFunctionAfterAlert } from "@/composables/swAlert";

export default defineComponent({
  name: "addEvento",
  props: {
    sessione_selezionata: {
      type: Object,
    },
    id_sessione_selezionata: {
      type: Number,
    },
  },
  components: {
    Loading,
    Datatable,
  },
  emits: ["refreshList"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const tableHeader = ref([
      // {
      //   name: "Nome sessione",
      //   key: "sessione",
      //   sortable: false,
      // },
      {
        name: "Circolo richiedente",
        key: "nome_circolo",
        sortable: false,
      },
      {
        name: "Data ora richiesta",
        key: "data_ora_richiesta",
        sortable: false,
      },
      {
        name: "N° biglietti",
        key: "numero_biglietti",
        sortable: false,
      },
      {
        name: "Richiesta confermata",
        key: "confermata",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const confirm = async (data) => {
      await callFunctionAfterAlert(
        "/sessione/ordine/confirm",
        { numero_biglietti: data.numero_biglietti },
        `Sei sicuro di voler modificare la richiesta del circolo <br> <b> ${data.nome_circolo}</b>?`,
        "Richiesta Modificata con successo",
        "Attenzione! Si è verificato un problema. Riprovare più tardi"
      ).then(({ success }) => {
        if (success) {
          emit("refreshList");
        }
      });
    };
    const refuse = async (data) => {
      await callFunctionAfterAlert(
        "/sessione/ordine/refuse",
        {},
        `Sei sicuro di voler modificare la richiesta del circolo <br> <b> ${data.nome_circolo}</b>?`,
        "Richiesta Modificata con successo",
        "Attenzione! Si è verificato un problema. Riprovare più tardi"
      ).then(({ success }) => {
        if (success) {
          emit("refreshList");
        }
      });
    };

    return {
      refuse,
      confirm,
      tableHeader,
      richieste_biglietti,
    };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
