import * as yup from "yup";

export const schema = yup.object().shape({
  nome: yup.string().required("Obbligatorio"),
  data: yup.string().required("Obbligatorio"),
  descrizione: yup.string().required("Obbligatorio"),
  id_tipo_sessione: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Obbligatorio"),
  prezzo: yup.number().required("Obbligatorio").typeError("Inserire un numero"),
  disponibilita_esaurita: yup.boolean(),
});
