<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getEventiList')">
          <div class="row">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Denominazione evento"
                  name="denominazione_evento"
                  type="text"
                  class="form-control"
                  @input="setNome($event.target.value)"
                  :value="nome_Societa"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="comitati"
                name="comitati"
                placeholder="Comitato"
                @changeSelect="setComitato($event)"
                :value="id_Comitato"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600"
              @click="$emit('getEventiList')"
              :disabled="loading"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                spliceTipologie();
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-Eventi-tennis",
  emits: ["resetFilters", "getEventiList"],
  props: {
    resetted: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  components: { SelectInput },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    // const eventi_stati = computed(() =>
    //   store.getters.getStateFromName("eventi_stati")
    // );
    // const comitati = computed(() => store.getters.getStateFromName("comitati"));

    // CARICAMENTO LOOKUPS
    // const keys = ref("d|ss|c");
    // verifico se le lookup esistono, altrimanti le carico da api
    // if (!discipline.value || !scuole_stati.value || !comitati.value) {
    //   store.dispatch("setStoreData", {
    //     keys: { keys: keys.value },
    //     apiLink: globalApi.COMBOLIST_GET,
    //   });
    // }

    // const id_Comitato = computed(() => store.getters.idComitatoEventi);
    const nomeEventi = computed(() => store.getters.nomeEventi);

    const setNome = (event) => {
      store.commit("setnomeEventi", event);
    };

    // const setComitato = (event) => {
    //   store.commit("setidComitatoEventi", event);
    // };

    const anno = computed(() => store.getters.stagioneSelected);
    watch(anno, () => {
      emit("getEventiList");
    });

    return {
      setNome,
      nomeEventi,
      eventi_tipo: computed(() =>
        store.getters.getStateFromName("eventi_tipo")
      ),
      societa: computed(() => store.getters.societaNomeCodAff),
      discipline,
      // eventi_stati,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedlista_richiesta_biglietti")
      ),
      // comitati,
      // setComitato,
    };
  },
});
</script>

<style></style>
