<template>
  <div>
    <FilterRichiestaBiglietti
      @getEventiList="searchEventiList"
      @resetFilters="resetFilters"
    />
    <TableRichiestaBiglietti
      @getEventiList="searchEventiList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import FilterRichiestaBiglietti from "@/components/components-fit/SNM/RichiestaBiglietti/FilterRichiestaBiglietti.vue";
import TableRichiestaBiglietti from "@/components/components-fit/SNM/RichiestaBiglietti/TableRichiestaBiglietti.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "lista-eventi",
  components: { FilterRichiestaBiglietti, TableRichiestaBiglietti },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Lista Eventi", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipEventi);
    const fetchRows = computed(() => store.getters.fetchRowsEventi);
    const sortColumn = computed(() => store.getters.sortColumnEventi);
    const sortOrder = computed(() => store.getters.sortOrderEventi);
    const nome = computed(() => store.getters.nomeEventi);

    const getEventiList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          nome: nome.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.LISTA_RICHIESTA_BIGLIETTI,
        itemName: "lista_richiesta_biglietti",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersEventi");
      getEventiList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedlista_richiesta_biglietti")
    );

    const searchEventiList = () => {
      if (!loaded.value) return;
      // store.commit("setRowsToSkipEventi");
      getEventiList();
    };

    getEventiList();

    return {
      getEventiList,
      resetFilters,
      searchEventiList,
    };
  },
});
</script>
