<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage"></loading>
    <div class="row mt-5">
      <!-- <div class="col-3">
        <div class="btn btn-light text-gray-700 fs-6 text-center">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioniEventi"
            :checked="tutteStagioniEventi"
          />visualizza tutte le stagioni
        </div>
      </div> -->
      <div class="col-6">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_evento"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi evento
          </button>
        </div>
      </div>
      <div class="col-6 text-end">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_eventi"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
    </div>
    <br />
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        lista_richiesta_biglietti.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="eventi.eventi"
        :total="eventi.eventi.length"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsEventi"
        @current-change="setCurrentPageEventi"
        :checkedAll="checkAll"
        @checkboxClicked="
          checkAll = !checkAll;
          lista_richiesta_biglietti.forEach((element) => {
            element.selected = checkAll;
          });
        "
      >
        <template v-slot:cell-checkbox="{ row: data }">
          <!--    //v-if="!data.pagato" -->
          <input
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
            :value="data.id"
            v-if="data.stato < 3"
            @click="checkAll = false"
        /></template>
        <template v-slot:cell-anno="{ row: data }">{{ data.anno }}</template>
        <template v-slot:cell-societa="{ row: data }"
          ><div class="tab-long">{{ data.societa }}</div></template
        >
        <template v-slot:cell-disciplina="{ row: data }">{{
          data.disciplina
        }}</template>
        <template v-slot:cell-tipologia="{ row: data }">{{
          data.tipologia
        }}</template>
        <template v-slot:cell-data_richiesta="{ row: data }">
          {{ getFormatDate(data.data_richiesta) }}</template
        >
        <template v-slot:cell-data_inizio="{ row: data }">
          {{ getFormatDate(data.data_inizio) }}</template
        >
        <template v-slot:cell-data_fine="{ row: data }">
          {{ getFormatDate(data.data_fine) }}</template
        >
        <template v-slot:cell-stato_scuola="{ row: data }"
          ><h1 v-if="data.stato == 2" class="badge badge-light">
            In approvazione
          </h1>
          <h1 v-if="data.stato == 1" class="badge badge-light-warning">
            Approvata temporaneamente
          </h1>
          <h1 v-if="data.stato == 4" class="badge badge-light-success">
            Approvata definitivamente
          </h1>
          <h1 v-if="data.stato == 3" class="badge badge-light-danger">
            Rifiutata
          </h1></template
        >
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <button
              class="btn btn-sm dropdown p-1"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_edit_sessione"
              type="button"
              @click="
                selectedScuolaId = data.id;
                selectedSocietaId = data.id_societa;
                selectedScuolaStato = data.stato;
              "
            >
              <i class="bi bi-pen text-dark fs-4"></i>
            </button>
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="
                data.stato < 3 ||
                (data.stato == 4 && isEnabled('fnEditScuolaApprovata'))
              "
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a
                href="#"
                v-if="data.stato == 2"
                @click.prevent="approvaSingoli(1, data.id)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check-circle text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Approva temporaneamente
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.stato == 1"
                @click.prevent="approvaSingoli(4, data.id)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check-circle text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Approva definitivamente
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a href="#" @click.prevent="setAzioni('rifiuta', data.id)">
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-x-circle text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Rifiuta </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.stato == 2"
                @click.prevent="setAzioni('elimina', data.id)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7">
                        Elimina richiesta
                      </span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <!-- TABELLA JSON FINTO -->
    <Datatable
      :table-header="tableHeader"
      :tableHeader2="tableHeader2"
      :table-data="eventi.eventi"
      :total="eventi.eventi.length"
      :current-page="currentPage"
      :rows-per-page="fetchRows"
      :sortLabel="sortColumn"
      :order="sortOrder"
      @sort="setSortOrderColumn"
      @items-per-page-change="setFetchRowsEventi"
      @current-change="setCurrentPageEventi"
      :checkedAll="checkAll"
      @checkboxClicked="
        checkAll = !checkAll;
        lista_richiesta_biglietti.forEach((element) => {
          element.selected = checkAll;
        });
      "
    >
      <template v-slot:cell-nome="{ row: data }">{{ data.nome }} </template>
      <template v-slot:cell-visibile_tutti_circoli="{ row: data }">
        <span class="text-success" v-if="data.visibile_a_tutti_i_circoli"
          >SI</span
        >
        <span class="text-danger" v-else>NO</span></template
      >
      <template v-slot:cell-options="{ row: data }">
        <div class="btn-group">
          <button
            class="btn btn-sm dropdown p-1"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_evento"
            type="button"
            @click="
              selected_evento = data;
              id_selected_evento = data.id;
            "
          >
            <i class="bi bi-pen text-dark fs-4"></i>
          </button>
        </div>
      </template>
      <template v-slot:cell-sessioni="{ row: data, index: idx }">
        <div class="accordion-item">
          <div class="accordion-header" :id="`TorneiRaas_int_${data.id}`">
            <button
              class="accordion-button m-0 p-3 collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#TorneiRaas_panel_${data.id}`"
              aria-expanded="falde"
              :aria-controls="`#TorneiRaas_panel_${data.id}`"
              :style="idx % 2 == 0 ? 'background-color: #f8fafb' : ''"
            >
              Sessioni:
            </button>
          </div>
          <div
            :id="`TorneiRaas_panel_${data.id}`"
            class="accordion-collapse collapse"
            :aria-labelledby="`TorneiRaas_int_${data.id}`"
            :style="idx % 2 == 0 ? 'background-color: #f8fafb' : ''"
          >
            <div class="accordion-body">
              <table class="table tablegy-3 gs-7 align-top">
                <thead>
                  <tr
                    class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                  >
                    <th>Nome sessione</th>
                    <th>Descrizione</th>
                    <th>Data</th>
                    <th>Tipologia</th>
                    <th>Prezzo</th>
                    <th>N° persone</th>
                    <th>Disponibilità esaurita</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sessione, index) in data.sessioni" :key="index">
                    <td>{{ sessione.nome }}</td>
                    <td>{{ sessione.descrizione }}</td>
                    <td>{{ format(new Date(sessione.data)) }}</td>
                    <td>{{ sessione.tipo_sessione }}</td>
                    <td>{{ sessione.prezzo }} €</td>
                    <td>{{ sessione.numero_persone }}</td>
                    <td>
                      <span
                        class="text-success"
                        v-if="sessione.disponibilita_esaurita"
                        >SI</span
                      >
                      <span class="text-danger" v-else>NO</span>
                    </td>
                    <td>
                      <div class="btn-group">
                        <button
                          class="btn btn-sm dropdown p-1"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_edit_sessione"
                          type="button"
                          @click="
                            sessione_selezionata = sessione;
                            id_sessione_selezionata = sessione.id;
                          "
                        >
                          <i class="bi bi-pen text-dark fs-4"></i>
                        </button>
                        <button
                          class="btn btn-sm dropdown p-1"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_modal_lista_richieste"
                          type="button"
                          @click="
                            sessione_selezionata = sessione;
                            id_sessione_selezionata = sessione.id;
                          "
                        >
                          <i class="bi bi-ticket-perforated text-dark fs-4"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class=""></div>
            </div>
          </div>
        </div>
      </template>
    </Datatable>
    <ModalAddEvento
      @refreshList="$emit('getEventiList')"
      :selected_evento="selected_evento"
      :id_selected_evento="id_selected_evento"
    />
    <ModalViewRichiesteSessione
      :sessione_selezionata="sessione_selezionata"
      :id_sessione_selezionata="id_sessione_selezionata"
      @refreshList="$emit('getEventiList')"
    />
    <ModalEditSessione
      :sessione_selezionata="sessione_selezionata"
      :id_sessione_selezionata="id_sessione_selezionata"
      @refreshList="$emit('getEventiList')"
    />
    <ModalEsportaRichiestaBiglietti />
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatableSottosezione.vue";
import { ref, computed } from "vue";
import isEnabled from "@/composables/isEnabled.js";
// componente spinner
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { useStore } from "vuex";
import eventi from "@/JSON/eventi.json";
import { format } from "@/composables/formatDate";
import ModalAddEvento from "./ModalAddEvento.vue";
import ModalViewRichiesteSessione from "./ModalViewRichiesteSessione.vue";
import ModalEditSessione from "./ModalEditSessione.vue";
import ModalEsportaRichiestaBiglietti from "./ModalEsportaRichiestaBiglietti.vue";

export default {
  name: "table-eventi-tennis",
  components: {
    Datatable,
    Loading,
    ModalAddEvento,
    ModalViewRichiesteSessione,
    ModalEditSessione,
    ModalEsportaRichiestaBiglietti,
  },
  emits: ["getEventiList", "resetFilters"],
  setup(props, { emit }) {
    const store = useStore();
    const checkAll = ref(false);
    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const selectedScuolaId = ref(null);
    const selectedSocietaId = ref(null);
    const selectedScuolaStato = ref(null);

    const tableHeader = ref([
      {
        name: "Nome evento",
        key: "nome",
        sortable: false,
      },
      {
        name: "Visibile a tutti i circoli",
        key: "visibile_tutti_circoli",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const tableHeader2 = ref([
      {
        name: "Sessioni",
        key: "sessioni",
      },
    ]);

    const disableUpdate = ref(0);

    const isLoading = ref(false);
    const fullPage = ref(true);

    const lista_richiesta_biglietti = computed(() =>
      store.getters.getStateFromName("resultslista_richiesta_biglietti")
    );

    const setTutteLeStagioniEventi = () => {
      store.commit("setTutteLeStagioniEventi");
      emit("getEventiList");
    };

    const setFetchRowsEventi = (e) => {
      store.commit("setFetchRowsEventi", e);
      emit("getEventiList");
    };
    const setCurrentPageEventi = (page) => {
      store.commit("setCurrentPageEventi", page);
      emit("getEventiList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnEventi", columnName);
      store.commit("setSortOrderEventi", order);
      emit("getEventiList");
    };

    const resetids = () => {
      selectedScuolaId.value = null;
      selectedSocietaId.value = null;
      selectedScuolaStato.value = null;
    };

    const sessione_selezionata = ref(null);
    const id_sessione_selezionata = ref(null);
    const selected_evento = ref(null);
    const id_selected_evento = ref(null);

    return {
      selected_evento,
      id_selected_evento,
      id_sessione_selezionata,
      sessione_selezionata,
      format,
      getFormatDate,
      tableHeader,
      selectedScuolaId,
      selectedSocietaId,
      selectedScuolaStato,

      disableUpdate,
      isLoading,
      fullPage,

      checkAll,
      isEnabled,
      lista_richiesta_biglietti,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedlista_richiesta_biglietti")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordlista_richiesta_biglietti")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuslista_richiesta_biglietti")
      ),
      tutteStagioniEventi: computed(() => store.getters.tutteStagioniEventi),
      setTutteLeStagioniEventi,
      currentPage: computed(() => store.getters.currentPageEventi),
      rowsToSkip: computed(() => store.getters.rowsToSkipEventi),
      fetchRows: computed(() => store.getters.fetchRowsEventi),
      sortColumn: computed(() => store.getters.sortColumnEventi),
      sortOrder: computed(() => store.getters.sortOrderEventi),
      setFetchRowsEventi,
      setCurrentPageEventi,
      setSortOrderColumn,

      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      resetids,
      eventi,
      tableHeader2,
    };
  },
};
</script>

<style scoped></style>
